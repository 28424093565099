import Vue from 'vue'
import '@/plugins/axios'
import App from './App.vue'
import router from './router'
import store from '@/store'
import '@/utils/permission'
import GlobalConfig from '@/config'
import '@/plugins/element.js'
import 'element-ui/lib/theme-chalk/display.css'
import 'element-ui/lib/theme-chalk/base.css'
import '@/assets/css/main.scss'
import myComponents from '@/components'
import '@/assets/icon/iconfont.css'
import api from '@/service'
import '@/directive'
import publicFunction from '@/utils/publicFunction.js'

Vue.use(myComponents)
Vue.use(GlobalConfig)

Vue.config.productionTip = false

Vue.prototype.$api = api
Vue.prototype.$publicFunction = publicFunction

// 兼容ie的vue-router跳转
if (
  '-ms-scroll-limit' in document.documentElement.style &&
  '-ms-ime-align' in document.documentElement.style
) { // detect it's IE11
  window.addEventListener('hashchange', function (event) {
    var currentPath = window.location.hash.slice(1)
    if (store.state.route.path !== currentPath) {
      router.push(currentPath)
    }
  }, false)
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
